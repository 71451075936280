export const API_CONFIG = {
    GET: "get",
    POST: "post",
    PUT: "put",
    PATCH: "patch",
    DELETE: "delete",
    BASE_URL_QA: "https://fcsqaapi.appnofy.com",
    BASE_URL_DEV: "https://fcsdevapi.appnofy.com",
    BASE_URL_PROD: "https://api.fix-carsharing.com"
};

const CONTROLLERS = {
    transactionController: "/transaction/history",
    userController: "/user",
    systemController: "/system",
    vehicleController: "/vehicle",
    documentController: "document",
    scheduleController: "/schedule",
    rideController: "/ride",
    chatController: "/chat",
    coupenController: "/coupon"
};

export const API_ROUTES = {
    TRANSACTION: {
        GET_SHARERS_EARNINGS_FOR_GRAPH: `${CONTROLLERS.transactionController}/admin/sharer/earning`,
        GET_PAYMENTS: `${CONTROLLERS.transactionController}/listing`
    },
    USER: {
        LOGIN: `${CONTROLLERS.userController}/login/admin`,
        REFRESH: `${CONTROLLERS.userController}/login-refresh`,
        SIGNUP: `${CONTROLLERS.userController}/signup/invited-admin`,
        GET_USER: `${CONTROLLERS.userController}/user/me`,
        LOGOUT: `${CONTROLLERS.userController}/logout`,
        GET_USERS_ONBOARDED_FOR_GRAPH: `${CONTROLLERS.userController}/admin/user/onboarding`,
        GET_USERS: `${CONTROLLERS.userController}/users/admin`,
        GET_USER_DOCUMENTS: `https://ocr.regily.com/verifications/results`,
        GET_USER_DETAILS: `${CONTROLLERS.userController}`,
        UPDATE_USER_STATUS: `${CONTROLLERS.userController}/update`,
        VERIFY_DOCUMENTS: `${CONTROLLERS.userController}/update-user-document-verification`,
        UPDATE_USER_APP_PASSWORD: `${CONTROLLERS.userController}/proceed-forget-password`,
        GET_SPECIFIC_USER_DOCS: `${CONTROLLERS.userController}/verification/document`,
        GET_CONFLICT_LISTING: `${CONTROLLERS.userController}/get-user-conflict-listing`,
        UPDATE_CONFLICT: `${CONTROLLERS.userController}/user-conflict-handler`,
        INVITE_ADMIN: `${CONTROLLERS.userController}/send-email-invite`
    },
    SYSTEM: {
        GET_SERVICE_RATES: `${CONTROLLERS.systemController}/config/listing`,
        ADD_UPDATE_SERVICE_RATES: `${CONTROLLERS.systemController}/config`,
        GET_SERVICE_RATES_COUNTRIES: `${CONTROLLERS.systemController}/config/regions`
    },
    VEHICLE: {
        GET_BRANDS: `${CONTROLLERS.vehicleController}/brands`,
        ADD_VEHICLE: `${CONTROLLERS.vehicleController}/add`,
        GET_VEHICLE_LISTING: `${CONTROLLERS.vehicleController}/vehicles`,
        DELETE_VEHICLE: `${CONTROLLERS.vehicleController}/{id}`
    },
    DOCUMENTS: {
        GET_DOCUMENTS_FOR_GRAPH: `${CONTROLLERS.documentController}/admins`
    },
    SCHEDULE: {
        GET_RIDES_FOR_GRAPH: `${CONTROLLERS.scheduleController}/admin/rides/analysis`
    },
    COUPEN: {
        GET_COUPEN_LISTING: `${CONTROLLERS.coupenController}/coupon/list`,
        ADD_UPDATE_COUPEN: `${CONTROLLERS.coupenController}/addupdate`
    },
    RIDE: {
        GET_RIDES: `${CONTROLLERS.rideController}/rides/admin`,
        GET_RIDE_SUMMARY: `${CONTROLLERS.rideController}/admin`,
        GET_SPECIFIC_USER_RIDES: `${CONTROLLERS.rideController}/admin/rides`
    }
};

export const CONTENT_TYPE = {
    JSON: "application/json",
    FORM_DATA: "multipart/form-data"
};

export const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    OK: 200,
    CREATED: 201,
    PAYLOAD_TOO_LARGE: 413,
    SERVER_ERROR: 500
};
