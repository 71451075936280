import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import React from "react";
import { useInviteAdmin } from "../../../apiServices/usersQueries";
import Logo from "../../../assets/logo/auth-logo.svg";
import { ADMIN_ROLE_OPTIONS } from "../../../constants/constant";
import notificationService from "./../../../util/notification.service";

export default function InviteAdmin({ setOpenModal }) {
    const [form] = Form.useForm();

    const { mutate: inviteAdmin, isLoading } = useInviteAdmin({ onSuccess });
    function onSuccess() {
        notificationService.success("Invitation Send Successfully");
        setOpenModal(false);
        form.resetFields();
    }

    return (
        <div className="auth-main-content">
            <div className="gx-d-flex gx-justify-content-center main-logo-container gx-mt-5">
                <img src={Logo} className="app-logo" alt="" />
            </div>
            <div className="auth-content-wrapper">
                <Form
                    initialValues={{ remember: true }}
                    name="login"
                    form={form}
                    onFinish={inviteAdmin}
                    layout="vertical"
                >
                    <Form.Item
                        required={false}
                        name="FullName"
                        validateTrigger="onBlur"
                        className="invite-admin-input"
                        rules={[
                            {
                                required: true,
                                message: "Full Name is Required"
                            }
                        ]}
                    >
                        <Input
                            className="auth-input"
                            placeholder="Enter Full Name"
                            prefix={<UserOutlined className="invite-admin-input-icons" />}
                        />
                    </Form.Item>
                    <Form.Item
                        required={false}
                        name="Email"
                        className="invite-admin-input"
                        label=""
                        initialValue=""
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: "Email is required."
                            },
                            { type: "email", message: "Email is Invalid" }
                        ]}
                    >
                        <Input
                            className="auth-input"
                            placeholder="Enter email"
                            prefix={<MailOutlined className="invite-admin-input-icons" />}
                        />
                    </Form.Item>
                    <Form.Item
                        required={false}
                        name="UserRoles"
                        validateTrigger="onBlur"
                        rules={[
                            {
                                required: true,
                                message: "Role is required."
                            }
                        ]}
                    >
                        <Select
                            showSearch={false}
                            mode="multiple"
                            options={ADMIN_ROLE_OPTIONS}
                            className="role-select"
                            placeholder="Select Role For Admin"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            className="gx-mb-0 gx-w-100 gx-py-2 login-btn noirProMedium gx-font-weight-medium"
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                        >
                            Invite Admin
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}
