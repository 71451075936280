import { useMutation, useQuery } from "react-query";
import { request } from "../API/index";
import { STORAGE_KEY } from "../constants/Storage";
import { API_CONFIG, API_ROUTES } from "./apiConfig";

export function useGetUsersListing(params) {
    return useQuery(
        [STORAGE_KEY.USERS, params],
        async () => {
            const { data } = await request({
                url: API_ROUTES.USER.GET_USERS,
                method: API_CONFIG.POST,
                params
            });
            return data;
        },
        { keepPreviousData: true, staleTime: Infinity }
    );
}

export function useGetUserDetailsListing(params, id, currentTab) {
    return useQuery(
        [`${STORAGE_KEY.USERS}_${currentTab}_${id}`, params],
        async () => {
            const { data } = await request({
                url: `${API_ROUTES.RIDE.GET_SPECIFIC_USER_RIDES}/${currentTab}/${id}`,
                method: API_CONFIG.POST,
                params
            });
            return data;
        },
        { keepPreviousData: true, staleTime: Infinity, refetchOnWindowFocus: "always" }
    );
}

export function useGetUserDocuments(id) {
    return useQuery(
        [`${STORAGE_KEY.USER_DOCUMENTS}`, id],
        async () => {
            const { data } = await request({
                url: `${API_ROUTES.USER.GET_SPECIFIC_USER_DOCS}/${id}`,
                method: API_CONFIG.GET
            });
            return data;
        },
        { keepPreviousData: true, staleTime: Infinity, enabled: !!id }
    );
}

export function useGetSpecificUserDetails(id) {
    return useQuery(
        [`${STORAGE_KEY.USERS}_${id}`, id],
        async () => {
            const { data } = await request({
                url: `${API_ROUTES.USER.GET_USER_DETAILS}/${id}`,
                method: API_CONFIG.GET
            });
            return data;
        },
        { keepPreviousData: true, staleTime: Infinity }
    );
}

export function useUserStatusUpdate(options) {
    return useMutation(async (params, id) => {
        const { data } = await request({
            url: `${API_ROUTES.USER.UPDATE_USER_STATUS}/${params.userId}/permissions`,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}
export function useUpdateVerifyDocuments(options) {
    return useMutation(async (params, id) => {
        const { data } = await request({
            url: API_ROUTES.USER.VERIFY_DOCUMENTS,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}

export function useGetUserConflictListing(params, options) {
    return useQuery(
        [STORAGE_KEY.CONFLICT_LISTING, params],
        async () => {
            const { data } = await request({
                url: API_ROUTES.USER.GET_CONFLICT_LISTING,
                method: API_CONFIG.POST,
                params: params
            });
            return data;
        },
        { keepPreviousData: true, staleTime: Infinity }
    );
}

export function useUserConflictUpdate(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: API_ROUTES.USER.UPDATE_CONFLICT,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}
export function useInviteAdmin(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: API_ROUTES.USER.INVITE_ADMIN,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}
export function useSignUpAdmin(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: API_ROUTES.USER.SIGNUP,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}
