import { Divider, Layout, Popover } from "antd";
import { toggleCollapsedSideNav } from "appRedux/slices/Settings";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import placeholderImg from "../../assets/images/anonymous.png";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";

import CustomModal from "../../components/Common/CustomModal/CustomModal";
import { STORAGE_KEY } from "../../constants/Storage";
import AuthService from "../../util/auth.service";
import utilService from "../../util/utils.service";
import InviteAdmin from "../AuthPages/InviteAdmin";
import "./index.less";

const { Header } = Layout;
const Topbar = () => {
    const isTokenExist = AuthService.isTokenExist();
    const [user, SetUser] = useState({});
    const { width, navCollapsed, navStyle } = useSelector(({ settings }) => settings);
    const adminProfile = JSON.parse(localStorage.getItem(STORAGE_KEY.ADMIN_INFO));
    const role = JSON.parse(localStorage.getItem(STORAGE_KEY.ROLES));
    const [openModal, setOpenModal] = useState(false);
    const [openInviteAdminModal, setOpenInviteAdminModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isTokenExist) {
            onHandleModal();
        } else {
            const { User } = adminProfile;
            SetUser(User);
        }
    }, [isTokenExist]);

    const onHandleModal = () => {
        localStorage.clear();
        utilService.redirectTo("/login");
    };

    const onHandleCancel = () => {
        setOpenModal(false);
        setOpenInviteAdminModal(false);
    };

    const renderModal = () => {
        return (
            <div>
                <div className="poppins-medium gx-fs-lg">Do you really want to Logout from the application ?</div>
            </div>
        );
    };

    const getTitle = () => {
        return (
            <div className="flex-row-center">
                <span className="profile-name-text">{user?.FullName}</span>
                <img className="cursor" src={require("assets/logo/arrow-down.svg")} />
            </div>
        );
    };

    const notificationMsgs = [
        {
            image: placeholderImg,
            name: "Jenny",
            message: "Jenny just scheduled a Ride"
        },
        {
            image: placeholderImg,
            name: "Alfred",
            message:
                "Alfred added a vehicle Michael just schedules a Ride Michael just schedules a Ride Michael just schedules a Ride"
        },
        {
            image: placeholderImg,
            name: "Michael",
            message: "Michael just schedules a Ride"
        }
    ];

    const dropdownContent = () => {
        return (
            <div className="notification-wrapper">
                <div className="custom-mb-14 gx-fs-md poppins-medium">Notifications</div>
                {notificationMsgs.map((item, index) => {
                    return (
                        <>
                            <div className="flex-row custom-mb-5">
                                <img width={34} height={34} src={item.image} className="round-img" />
                                <div>
                                    <div className="gx-fs-sm poppins-medium">{item.name}</div>
                                    <div className="gx-fs-xs poppins-regular fc-text-gray">{item.message}</div>
                                </div>
                            </div>
                            <div className="divider-container">
                                <div className="notification-divider" />
                            </div>
                        </>
                    );
                })}
                {notificationMsgs.length > 2 && <div className="gx-fs-sm see-more poppins-medium">See more</div>}
            </div>
        );
    };

    return (
        <Header className="justify-between">
            {navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
                <div className="gx-linebar gx-mr-3 text-color-white">
                    <i
                        className="gx-icon-btn icon toggle-menu icon-menu gx-text-white"
                        onClick={() => {
                            dispatch(toggleCollapsedSideNav(!navCollapsed));
                        }}
                    />
                </div>
            ) : (
                <div />
            )}
            <div className="flex-row-center">
                {/* <Dropdown overlay={dropdownContent} trigger={['click']} placement="bottomRight">
                    <img src={notificationIcon} className="cursor custom-mr-22" />
                </Dropdown> */}
                <Popover
                    placement="bottom"
                    className="flex-row-center gx-p-0 gx-pointer"
                    trigger={"click"}
                    content={
                        <div className="logout-wrapper gx-pointer">
                            <div className="gx-fs-md poppins-medium" onClick={() => setOpenModal(true)}>
                                Logout
                            </div>
                            <Divider />
                            {role?.includes(1) && (
                                <div className="gx-fs-md poppins-medium" onClick={() => setOpenInviteAdminModal(true)}>
                                    Invite Admin
                                </div>
                            )}
                        </div>
                    }
                >
                    <img
                        alt=""
                        width={40}
                        height={40}
                        className="gx-mr-1 round-img"
                        src={adminProfile?.User?.ProfileImage ? adminProfile?.User?.ProfileImage?.Url : placeholderImg}
                    />
                    <h1 className="margin-0 poppins-medium gx-fs-lg dark-text">{getTitle()}</h1>
                </Popover>
            </div>
            <CustomModal
                visible={openModal}
                body={renderModal()}
                okText={"Yes"}
                cancelText={"No"}
                handleCancel={onHandleCancel}
                handleOkSubmit={onHandleModal}
                title={"Logout Confirmation"}
                closable={false}
            />
            <CustomModal
                visible={openInviteAdminModal}
                body={<InviteAdmin setOpenModal={setOpenInviteAdminModal} />}
                footer={null}
                handleCancel={onHandleCancel}
                closable={false}
                cssClass="invite-admin-modal"
            />
        </Header>
    );
};

export default Topbar;
