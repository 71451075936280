import { useMutation } from "react-query";
import { request } from "../API";
import { API_CONFIG, API_ROUTES } from "./apiConfig";

export function useLogin(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: API_ROUTES.USER.LOGIN,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}

export function useResetAppPassword(options) {
    return useMutation(async (params) => {
        const { data } = await request({
            url: API_ROUTES.USER.UPDATE_USER_APP_PASSWORD,
            method: API_CONFIG.POST,
            params: params
        });
        return data;
    }, options);
}