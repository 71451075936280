import { create } from "apisauce";
import { onlineManager } from "react-query";
import { API_CONFIG, API_ROUTES, CONTENT_TYPE, HTTP_STATUS } from "../apiServices/apiConfig";
import { STORAGE_KEY } from "../constants/Storage";
import utilService from "../util/utils.service";
import LocalStorageService from "./local-storage.service";

export const BASE_URL = API_CONFIG.BASE_URL_PROD;

const apiSauceInstance = create({
    baseURL: BASE_URL
});

// export const setBaseRoute = (newBase) => {
//   apiSauceInstance.setBaseURL(
//     `${newBase}${API_SERVICE.CULTURE}${API_SERVICE.VERSION}`,
//   );
// };

async function get(url, queryParams, config) {
    const response = await apiSauceInstance.get(url, queryParams, config);
    return handleResponse(response);
}

async function post(url, data, config) {
    const response = await apiSauceInstance.post(url, data, config);
    return handleResponse(response);
}

async function put(url, data, config) {
    const response = await apiSauceInstance.put(url, data, config);
    return handleResponse(response);
}

async function patch(url, data, config) {
    const response = await apiSauceInstance.patch(url, data, config);
    return handleResponse(response);
}

async function deleteReq(url, queryParams, config) {
    const response = await apiSauceInstance.delete(url, queryParams, config);
    return handleResponse(response);
}

function handleResponse(response) {
    console.log("API RESPONSE:", response);
    const mutatedResponse = {
        ok: response.ok,
        status: response.status,
        response: {
            code: utilService.getValue(response.data, "Code", response.status),
            message: utilService.getValue(response.data, "Message", response?.problem)
            //   errorCode: utilService.getValue(response.data, "response.errorCode", 400),
        },
        data: !utilService.isEmpty(response.data) ? response.data : null
    };
    switch (response?.problem) {
        // case "CANCEL_ERROR":
        //   mutatedResponse["response"]["message"] = "Request has been cancelled.";
        //   break;
        case "NETWORK_ERROR":
            mutatedResponse["response"]["message"] = "Network not available";
            break;
        case "SERVER_ERROR":
            mutatedResponse["response"]["message"] = "Something went wrong";
            break;
        case "TIMEOUT_ERROR":
            mutatedResponse["response"]["message"] = "Server didn't respond in time";
            break;
        default:
            break;
    }
    // const data = utilService.getValue(response.data, "Data", response.data);
    if (response.status === HTTP_STATUS.UNAUTHORIZED) {
        let msg = mutatedResponse["response"]["message"] ?? "You are not authorized to perform this action";
        mutatedResponse["response"]["message"] = msg;
        // logoutUser();
        // notificationService.error(msg);
        // LocalStorageService.remove(STORAGE_KEY.ADMIN_INFO);
        // utilService.redirectToLogin();
    }
    if (response.status === HTTP_STATUS.SERVER_ERROR) {
        //Error message
        // toastService.fail(mutatedResponse["response"]["message"]);
    }

    if (response.ok) {
        return mutatedResponse;
    }

    return mutatedResponse;
}

let isRefreshing = false;

apiSauceInstance.addResponseTransform(async (response) => {
    if (response?.status == HTTP_STATUS.UNAUTHORIZED && !response?.config?.url.includes(API_ROUTES.USER.REFRESH)) {
        onlineManager.setOnline(false);

        if (!isRefreshing) {
            isRefreshing = true;

            let Token = LocalStorageService.get(STORAGE_KEY.TOKEN);
            let RefreshToken = LocalStorageService.getObject(STORAGE_KEY.ADMIN_INFO).RefreshToken;

            apiSauceInstance
                .post(API_ROUTES.USER.REFRESH, {
                    Token,
                    RefreshToken,
                    DeviceUUID: ""
                })
                .then((res) => {
                    if (!res.ok) {
                        localStorage.clear();
                        utilService.redirectTo("/login");
                    } else {
                        LocalStorageService.set(STORAGE_KEY.TOKEN, res?.data.Token);
                        LocalStorageService.setObject(STORAGE_KEY.ADMIN_INFO, res?.data);
                        onlineManager.setOnline(true);
                    }
                })
                .catch(console.log)
                .finally(() => ((isRefreshing = false), onlineManager.setOnline(true)));
        }
    }
});

apiSauceInstance.addAsyncRequestTransform(async (request) => {
    const token = await LocalStorageService.get(STORAGE_KEY.TOKEN);
    request.headers["Authorization"] = token;
    request.headers["Content-Type"] = CONTENT_TYPE.JSON;
    // request.headers["Access-Control-Allow-Origin"] = "*";
});

export default {
    get,
    post,
    patch,
    put,
    delete: deleteReq
};
