import React from "react";
import Home from "../assets/side-menu-icons/home.svg";
import ManageRide from "../assets/side-menu-icons/manage-ride.svg";
import PaymentCard from "../assets/side-menu-icons/payment-card.svg";
import Promotion from "../assets/side-menu-icons/promotion.svg";
import Service from "../assets/side-menu-icons/service.svg";
import User from "../assets/side-menu-icons/user.svg";
import WhiteHome from "../assets/side-menu-icons/white-home.svg";
import WhiteManageRide from "../assets/side-menu-icons/white-manage-ride.svg";
import WhitePaymentCard from "../assets/side-menu-icons/white-payment-card.svg";
import WhitePromotion from "../assets/side-menu-icons/white-promotion.svg";
import WhiteService from "../assets/side-menu-icons/white-service.svg";
import WhiteUser from "../assets/side-menu-icons/white-user.svg";
import { AUTHENTICATED_ROUTES } from "../routes/constant";

export const ROLE_DEFAULT_ROUTE = {
    Admin: AUTHENTICATED_ROUTES.DASHBOARD,
    User: AUTHENTICATED_ROUTES.DASHBOARD
};

export const MAIN_SIDE_BAR_OPTIONS = [
    {
        text: "Dashboard",
        linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
        selectedOptionKey: "dashboard",
        icon: <img src={Home} />,
        selectedIcon: <img src={WhiteHome} />,
        allowedRoles: [1, 4]
    },
    {
        text: "Users",
        linkTo: AUTHENTICATED_ROUTES.USER,
        selectedOptionKey: "user",
        icon: <img src={User} />,
        selectedIcon: <img src={WhiteUser} />,
        allowedRoles: [1, 2, 4]
    },
    {
        text: "Service Rates",
        linkTo: AUTHENTICATED_ROUTES.SERVICE_RATE,
        selectedOptionKey: "service-rates",
        icon: <img src={Service} />,
        selectedIcon: <img src={WhiteService} />,
        allowedRoles: [1, 2, 4]
    },
    {
        text: "Offer & Promotions",
        linkTo: AUTHENTICATED_ROUTES.OFFER_AND_PROMOTION,
        selectedOptionKey: "offer-and-promotions",
        icon: <img src={Promotion} />,
        selectedIcon: <img src={WhitePromotion} />,
        allowedRoles: [1, 2, 4]
    },
    {
        text: "Payments",
        linkTo: AUTHENTICATED_ROUTES.PAYMENT,
        selectedOptionKey: "payments",
        icon: <img src={PaymentCard} />,
        selectedIcon: <img src={WhitePaymentCard} />,
        allowedRoles: [1, 4]
    },
    {
        text: "Manage Rides",
        linkTo: AUTHENTICATED_ROUTES.MANAGE_RIDES,
        selectedOptionKey: "manage-rides",
        icon: <img src={ManageRide} />,
        selectedIcon: <img src={WhiteManageRide} />,
        allowedRoles: [1, 2, 4]
    },
    {
        text: "Activation Requests",
        linkTo: AUTHENTICATED_ROUTES.ACTIVATION_REQUESTS,
        selectedOptionKey: "activation-requests",
        icon: <img src={User} />,
        selectedIcon: <img src={WhiteUser} />,
        allowedRoles: [1, 2, 4]
    }
];
