import React from "react";
import { ReactComponent as CigarIcon } from "../assets/images/cigar.svg";
import { ReactComponent as FoodIcon } from "../assets/images/food.svg";
import { ReactComponent as GenderIcon } from "../assets/images/gender.svg";
import { ReactComponent as LuggageIcon } from "../assets/images/luggage.svg";
import { ReactComponent as PetIcon } from "../assets/images/pet.svg";

export const PAGINATION_CONSTANT = {
    PAGE_SIZE: 10,
    PAGE_NUMBER: 1
};

export const tableSort = {
    ASCENDING: "ascend",
    DESCENDING: "descend"
};

export const DESIGNATION_PAGINATION_CONSTANT = {
    PAGE_SIZE: 5,
    PAGE_NUMBER: 1
};

export const pageProperties = {
    WIDTH: 1024,
    OVERFLOW_Y: 250
};

export const STORAGE_CONST = {
    TOKEN: "TOKEN",
    EXTERNAL_TOKEN: "EXTERNAL_TOKEN",
    THEME_TYPE: "theme_type",
    ADMIN_INFO: "ADMIN_INFO",
    NEIGHBORHOOD_LOOKUP: "NEIGHBORHOOD_LOOKUP"
};

export const supportedFileFormat = ["image/jpg", "image/jpeg", "image/png", "image/jfif"];

export const TOPIC_LOOKUP_TYPE = {
    ALL_NEIGHORHOOD: "2",
    CHAT_TOPICS: "1"
};

export const API_STORAGE_KEY = {
    VIEW_ACTIVITY: "VIEW_ACTIVITY",
    VIEW_ACTIVITY_LIST: "VIEW_ACTIVITY_LIST",
    GENERAL_TAG_LOOKUP: "GENERAL_TAG_LOOKUP",
    ACCOMMODATION_LOOKUP: "ACCOMMODATION_LOOKUP",
    TRANSPORTATION_LOOKUP: "TRANSPORTATION_LOOKUP",
    NEIGHBORHOOD_LOOKUP: "NEIGHBORHOOD_LOOKUP",
    GENERAL_TAG_KEY: "GENERAL_TAG",
    ACCOMMODATION_KEY: "ACCOMMODATION",
    TRANSPORTATION_KEY: "TRANSPORTATION",
    FREEBIE_CATEGORY: "FREEBIE_CATEGORY",
    REPORTED_FREEBIES: "REPORTED_FREEBIES",
    USER_DETAILS: "USER_DETAILS",
    CATEGORY_LIST: "CATEGORY_LIST",
    ACCESS_LIST: "ACCESS_LIST",
    EXPLORE_LIST: "EXPLORE_LIST",
    ACCESS_LOOKUP: "ACCESS_LOOKUP",
    EXPLORE_LOOKUP: "EXPLORE_LOOKUP",
    CHAT_POST: "CHAT_POST",
    CHAT_COMMENT: "CHAT_COMMENT",
    REPLIES_DATA: "REPLIES_DATA",
    SUB_REPLIES_DATA: "SUB_REPLIES_DATA",
    PROFILE_INFO: "PROFILE_INFO",
    TIMEZONE_LOOKUP: "TIMEZONE_LOOKUP",
    CHAT_TOPICS: "CHAT_TOPICS",
    CHAT_ALL_TOPICS: "CHAT_ALL_TOPICS",
    SUGGESTED_TOPICS: "SUGGESTED_TOPICS",
    TOPIC_LOOKUP: "TOPIC_LOOKUP"
};

export const RESOURCE_TYPE = {
    PARENT: 0,
    PDF: 1,
    LINK: 2
};

export const CATEGORY_TYPE = {
    CATEGORY: {
        name: "Category",
        value: 1
    },
    SUB_CATEGORY: {
        name: "Sub Category",
        value: 2
    }
};

export const LOGOUT_KEYS = [STORAGE_CONST.TOKEN, STORAGE_CONST.ADMIN_INFO, STORAGE_CONST.EXTERNAL_TOKEN];

export const ADMIN_ROLE_OPTIONS = [
    { label: "Service", value: 2 },
    { label: "Technician", value: 3 },
    { label: "Developer", value: 4 }
];

export const APP_NAME_MINI = "Fix Car Sharer";

export const THEME = {
    LITE: "light",
    DARK: "dark"
};

export const SORT_ORDER = {
    ASC: "ascend",
    DESC: "descend"
};

export const SORT_ORDER_VALUE = {
    ascend: "ASC",
    descend: "DESC"
};

export const CODE_FORMATS = {
    Alphanumeric: 1,
    Alphabetic: 2,
    Numeric: 3
};

export const CODE_FORMAT_OPTIONS = [
    {
        value: 1,
        name: "Alphanumeric"
    },
    {
        value: 2,
        name: "Alphabetic"
    },
    {
        value: 3,
        name: "Numeric"
    }
];

export const OFFERS_AND_PROMOTION_STATUS = {
    1: "Unused",
    2: "Used"
};
export const VIEW_USER_DETAILS = [
    {
        id: 1,
        name: "As Seeker"
    },
    {
        id: 2,
        name: "As Sharer"
    },
    {
        id: 3,
        name: "Driver's Documents"
    }
];

export const MANAGE_RIDE_STATUS = {
    1: {
        title: "On Schedule",
        class: "st-active",
        colorClass: "st-success",
        color: "#0ba82a8c"
    },
    2: {
        title: "On Ride",
        class: "st-active",
        colorClass: "st-success",
        color: "#0ba82a8c"
    },
    3: {
        title: "Completed",
        class: "st-active",
        colorClass: "st-success",
        color: "#0ba82a8c"
    },
    4: {
        title: "Cancelled",
        class: "st-inactive",
        colorClass: "st-cancelled",
        color: "#ff00008c"
    },
    5: {
        title: "Expired",
        class: "st-inactive",
        colorClass: "st-cancelled",
        color: "#ff00008c"
    },
    6: {
        title: "Deleted",
        class: "st-inactive",
        colorClass: "st-cancelled",
        color: "#ff00008c"
    },
    10: {
        title: "Created",
        class: "st-active",
        colorClass: "st-success",
        color: "#0ba82a8c"
    }
};

export const ScheduleType = {
    Seeker: 1,
    Sharer: 2
};

export const RIDE_STATUS_ENUM = [
    {
        id: -1,
        value: null
    },
    {
        id: 1,
        value: "Confirmed"
    },
    {
        id: 2,
        value: "Ongoing"
    },
    {
        id: 3,
        value: "Completed"
    },
    {
        id: 4,
        value: "Cancelled"
    },
    {
        id: 5,
        value: "Expired"
    },
    {
        id: 6,
        value: "Deleted"
    }
];

export const REGION_TYPES = {
    COUNTRY: 1,
    STATE: 2,
    CITY: 3
};
export const PAYMENT_TABS = [
    {
        id: 1,
        name: "Dispersals"
    },
    {
        id: 2,
        name: "Receivables"
    }
];

export const USER_STATUSES = [
    {
        label: "Active",
        value: false
    },
    {
        label: "Blocked",
        value: true
    }
];

export const USER_STATUS = {
    0: "Unverified",
    100: "Pending Verification",
    200: "Email Verified",
    1000: "Active",
    1100: "Suspended",
    1101: "Pending Payment",
    1102: "User Self Deactivation"
};

export const USERS_TABLE_STATUSES = {
    true: "Active",
    false: "Inactive"
};

export const USER_SEEKER_STATUSES = [
    {
        label: "Seeker Active",
        value: true
    },
    {
        label: "Seeker Inactive",
        value: false
    }
];

export const USERS_TABLE_SEEKER_STATUSES = {
    true: "Seeker Active",
    false: "Seeker Inactive"
};

export const USER_SHARER_STATUSES = [
    {
        label: "Sharer Active",
        value: true
    },
    {
        label: "Sharer Inactive",
        value: false
    }
];

export const USERS_TABLE_SHARER_STATUSES = {
    true: "Sharer Active",
    false: "Sharer Inactive"
};

export const USERS_TABLE_DOCUMENTS_STATUSES = {
    true: "Verified Documents",
    false: "Unverified Documents"
};

export const ACTIONS = {
    VIEW: 1,
    EDIT: 2,
    DELETE: 3,
    STATUS: 4,
    SEEKER_STATUS: 5,
    SHARER_STATUS: 6,
    DOCUMENTS_STATUS: 7,
    MAKE_DEFAULT: 8
};

export const RIDE_FACILITIES_QA = [
    {
        RidePreferenceId: 18,
        Description: "Luggage space",
        icon: <LuggageIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 19,
        Description: "Smoking is allowed",
        icon: <CigarIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 20,
        Description: "Food & drink are allowed",
        icon: <FoodIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 21,
        Description: "Pets are allowed",
        icon: <PetIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 22,
        Description: "Only Males",
        icon: <GenderIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 23,
        Description: "Only Females",
        icon: <GenderIcon fill="white" stroke="#707070" />
    }
];

export const RIDE_FACILITIES_DEV = [
    {
        RidePreferenceId: 1,
        Description: "Luggage space",
        icon: <LuggageIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 2,
        Description: "Smoking is allowed",
        icon: <CigarIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 3,
        Description: "Food & drink are allowed",
        icon: <FoodIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 4,
        Description: "Pets are allowed",
        icon: <PetIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 5,
        Description: "Only Males",
        icon: <GenderIcon fill="white" stroke="#707070" />
    },
    {
        RidePreferenceId: 6,
        Description: "Only Females",
        icon: <GenderIcon fill="white" stroke="#707070" />
    }
];

export const DOCUMENTS_STATUS = {
    done: {
        title: "Active",
        class: "st-active",
        colorClass: "st-success"
    },
    unverified: {
        title: "Inactive",
        class: "st-inactive",
        colorClass: "st-cancelled"
    }
};

export const MANAGE_RIDE_FILTER_STATUSES = [
    {
        label: "On Schedule",
        value: 1
    },
    {
        label: "On Ride",
        value: 2
    },
    {
        label: "Completed",
        value: 3
    },
    {
        label: "Cancelled",
        value: 4
    },
    {
        label: "Expired",
        value: 5
    },
    {
        label: "Deleted",
        value: 6
    },
    {
        label: "Created",
        value: 10
    }
];

export const ACTIVATION_REQUESTS_FILTERS = [
    {
        label: "Approval Requested",
        value: 2
    },
    {
        label: "Approval Accepted",
        value: 3
    },
    {
        label: "Approval Rejected",
        value: 4
    },
    {
        label: "Resolved Successfully",
        value: 5
    }
];

export const ACTIVATION_REQUESTS_TYPES = {
    Pending: 1,
    ApprovalRequested: 2,
    ApprovalAccepted: 3,
    ApprovalRejected: 4,
    ResolvedSuccessfully: 5
};
export const GET_ACTIVATION_REQUEST_TYPES = {
    1: "Pending",
    2: "Approval Requested",
    3: "Approval Accepted",
    4: "Approval Rejected",
    5: "Resolved Successfully"
};

export const FILTER_OPERATORS = {
    Equals: "eq",
    NotEquals: "nq",
    Contains: "ctn",
    GreaterThan: "gt",
    GreaterThanEquals: "gte",
    LessThan: "lt",
    LessThanEquals: "lte",
    In: "in",
    Like: "like"
};

export const RIDE_TYPES = {
    true: {
        title: "Recurring"
    },
    false: {
        title: "One Time"
    }
};

export const PAYMENT_STATUS = {
    1: { title: "Pending ", class: "st-pending", colorClass: "st-pending", color: "#ffd9008c" },
    3: { title: "Cancelled ", class: "st-inactive", colorClass: "st-cancelled", color: "#ff00008c" },
    4: { title: "Pending Payout", class: "st-pending", colorClass: "st-pending", color: "#ffd9008c" },
    7: { title: "Completed ", class: "st-active", colorClass: "st-success", color: "#0ba82a8c" },
    8: { title: "Declined ", class: "st-inactive", colorClass: "st-cancelled", color: "#ff00008c" }
};

export const FOOTER_TEXT_APP_NAME =
    "Copyrights 2022 - Fix Car Sharer Developed by USD (Unique Software Development) - All Rights Reserved! ®";

export const SCHEDULE_SLOTS = {
    1: "00:00-00:30",
    2: "00:30-01:00",
    3: "01:00-01:30",
    4: "01:30-02:00",
    5: "02:00-02:30",
    6: "02:30-03:00",
    7: "03:00-03:30",
    8: "03:30-04:00",
    9: "04:00-04:30",
    10: "04:30-05:00",
    11: "05:00-05:30",
    12: "05:30-06:00",
    13: "06:00-06:30",
    14: "06:30-07:00",
    15: "07:00-07:30",
    16: "07:30-08:00",
    17: "08:00-08:30",
    18: "08:30-09:00",
    19: "09:00-09:30",
    20: "09:30-10:00",
    21: "10:00-10:30",
    22: "10:30-11:00",
    23: "11:00-11:30",
    24: "11:30-12:00",
    25: "12:00-12:30",
    26: "12:30-13:00",
    27: "13:00-13:30",
    28: "13:30-14:00",
    29: "14:00-14:30",
    30: "14:30-15:00",
    31: "15:00-15:30",
    32: "15:30-16:00",
    33: "16:00-16:30",
    34: "16:30-17:00",
    35: "17:00-17:30",
    36: "17:30-18:00",
    37: "18:00-18:30",
    38: "19:30-19:00",
    39: "19:00-19:30",
    40: "19:30-20:00",
    41: "20:00-20:30",
    42: "20:30-21:00",
    43: "21:00-21:30",
    44: "21:30-22:00",
    45: "22:00-22:30",
    46: "22:30-23:00",
    47: "23:00-23:30",
    48: "23:30-24:00"
};
