import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import Logo from "../../../assets/logo/auth-logo.svg";
import Footer from "../../../components/Footer";
import { UNAUTHENTICATED_ROUTES } from "../../../routes/constant";
import utilService from "../../../util/utils.service";
import { useParams } from "react-router-dom";
import { useResetAppPassword } from "../../../apiServices/authenticationQueries";
import "../Auth.less";
import { Patterns } from "../../../constants/ActionTypes";

const ResetAppPassword = () => {
    const [form] = Form.useForm();
    const { resetCode, Email } = useParams();
    function onSuccess(data) {
        utilService.redirectTo(UNAUTHENTICATED_ROUTES.EVENT_SUCCESS);
    }
    const { mutateAsync: resetAppPassword, isLoading: isResetLoading } = useResetAppPassword({ onSuccess });

    const onFinish = async ({ NewPassword }) => {
        const resetPayload = {
            NewPassword: NewPassword,
            Email,
            Code: resetCode,
            FCMToken: ""
        };
        resetAppPassword(resetPayload);
    };
    return (
        <>
            <div className="authContainer">
                <Row justify="center" className="gx-w-100">
                    <Col xxl={6} xl={8} lg={12} md={14} sm={20} xs={24}>
                        <div className="auth-main-content">
                            <div className="gx-d-flex gx-justify-content-center main-logo-container gx-mt-5">
                                <img src={Logo} className="app-logo" alt="" />
                            </div>
                            <div className="auth-content-wrapper reset-password-form-container">
                                <Form
                                    initialValues={{ remember: true }}
                                    name="resetPasswordForm"
                                    form={form}
                                    onFinish={onFinish}
                                    layout="vertical"
                                >
                                    <Form.Item
                                        required={false}
                                        initialValue=""
                                        validateTrigger="onBlur"
                                        label=""
                                        name="NewPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "New Password is required."
                                            },
                                            {
                                                pattern: Patterns.passwordPattern3,
                                                message:
                                                    "Password must have at least 8 characters , an  uppercase letter, a smallcase letter , a special character and a number"
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            className="auth-input"
                                            placeholder="New Password"
                                            prefix={<LockOutlined className="gx-px-2" />}
                                            iconRender={(visible) =>
                                                visible ? (
                                                    <EyeTwoTone color="##9f9f9f" className="auth-icon-color" />
                                                ) : (
                                                    <EyeInvisibleOutlined />
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        required={false}
                                        initialValue=""
                                        validateTrigger="onBlur"
                                        label=""
                                        name="ConfirmPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Confirm Password is required."
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("NewPassword") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        new Error("Password and Confirm Password does not Match ")
                                                    );
                                                }
                                            })
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            className="auth-input"
                                            placeholder="Confirm Password"
                                            prefix={<LockOutlined className="gx-px-2" />}
                                            iconRender={(visible) =>
                                                visible ? (
                                                    <EyeTwoTone color="##9f9f9f" className="auth-icon-color" />
                                                ) : (
                                                    <EyeInvisibleOutlined />
                                                )
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            className="gx-mb-0 gx-w-100 gx-py-2 login-btn noirProMedium gx-font-weight-medium"
                                            type="primary"
                                            htmlType="submit"
                                            loading={isResetLoading}
                                            disabled={isResetLoading}
                                        >
                                            Update Password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer className="app-footer noirProRegular gx-text-black mediumLine" footerClass="footer auth-footer" />
        </>
    );
};

export default ResetAppPassword;
