import "assets/vendors/style";
import { ConnectedRouter } from "connected-react-router";
import { ReactQueryDevtools } from "react-query/devtools";

import { QueryClient, QueryClientProvider } from "react-query";
import React from "react";
import { Provider } from "react-redux";
import "assets/vendors/style";
import { Switch } from "react-router-dom";
import configureStore, { history } from "./appRedux/store";
import withClearCache from "./ClearCache";
import FullPageLoader from "./components/FullPageLoader";
import { getNextPageParam } from "./util/react-query.service";
import LocalStorageService from "./util/local-storage.service";
import AuthService from "./util/auth.service";
import { STORAGE_CONST, THEME } from "./constants/constant";
import MainApp from "./containers/App/MainApp";
import UnAuthenticatedApp from "./routes/unauthenticated-app-routes";
import ResetAppPassword from "./containers/AuthPages/ResetAppPassword";
import CustomRoute from "./routes/CustomRoute";
import { UNAUTHENTICATED_ROUTES } from "./routes/constant";
const store = configureStore();

const isResetUser = history.location.pathname.includes("/reset-password");
const isTokenExist = AuthService.isTokenExist();

const localSaveTheme = LocalStorageService.get(STORAGE_CONST.THEME_TYPE);
const currentTheme = localSaveTheme && isTokenExist ? localSaveTheme : THEME.LITE;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 30,
            refetchOnWindowFocus: false,
            retry: false,
            getNextPageParam: (lastPage, allPages) => getNextPageParam(lastPage, allPages)
        }
    }
});
const RegisterRoutes = () => {
    if (isResetUser) {
        localStorage.clear();
        return (
            <Switch>
                <CustomRoute
                    exact
                    path={UNAUTHENTICATED_ROUTES.RESET_APP_PASSWORD}
                    component={ResetAppPassword}
                    title="Reset Password"
                />
            </Switch>
        );
    } else {
        if (isTokenExist) {
            return <MainApp />;
        } else {
            return <UnAuthenticatedApp />;
        }
    }
};

const App = () => (
    <React.Suspense fallback={<FullPageLoader />}>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ConnectedRouter history={history}>{RegisterRoutes()}</ConnectedRouter>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </Provider>
    </React.Suspense>
);

const ClearCacheComponent = withClearCache(App);

const NextApp = () => {
    return <ClearCacheComponent />;
};

export default NextApp;
