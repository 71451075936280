import moment from "moment";
import notificationService from "./notification.service";

const convertIntoUnix = (date) => {
    return moment(date).local().unix();
};

const convertFromUnix = (date, format = "MM/DD/YYYY", fromCurrentTime) => {
    if (fromCurrentTime) {
        return moment.unix(date).fromNow();
    }
    return moment.unix(date).format(format);
};

const convertDateTime = (date, format, fromCurrentTime) => {
    if (fromCurrentTime && format) {
        return moment(date, format).fromNow();
    }
    if (fromCurrentTime) {
        return moment(date).fromNow();
    }
    return moment(date).format(format);
};

const covertToLocalDateTime = (date, format) => {
    if (date) {
        return moment.utc(date).local().format(format);
    } else {
        return "N/A";
    }
};

const filterForRoles = (data, rolesForCurrentUser, keyToCheckDuplicates) => {
    if (!rolesForCurrentUser || typeof rolesForCurrentUser !== "object") {
        notificationService.error("Something went wrong please login again");

        return [];
    }
    return data.reduce((accumulator, currentValue) => {
        const linkToSet = new Set(accumulator.map((prop) => prop[keyToCheckDuplicates]));
        rolesForCurrentUser.forEach((role) => {
            if (currentValue.allowedRoles.includes(role) && !linkToSet.has(currentValue[keyToCheckDuplicates])) {
                accumulator.push(currentValue);
                linkToSet.add(currentValue.linkTo);
            }
        });
        return accumulator;
    }, []);
};

const getLocal = (format) => {
    return moment().local().format(format);
};

const getFromToday = (format, number, identifier) => {
    return moment().local().subtract(number, identifier).format(format);
};

function isAfter(date1, date2) {
    return moment(date1).isAfter(date2);
}

const normalizeOptionsForSelector = (data) => {
    if (data?.Data) {
        const normalizedData = data.Data.map((item) => {
            return {
                label: item.Name,
                value: item.Id
            };
        });
        return normalizedData;
    }
    return [];
};

const parseJwt = (token) => {
    if (!token) return null;
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};
export {
    convertIntoUnix,
    convertFromUnix,
    convertDateTime,
    covertToLocalDateTime,
    getLocal,
    isAfter,
    getFromToday,
    normalizeOptionsForSelector,
    parseJwt,
    filterForRoles
};
