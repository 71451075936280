import React from "react";
import PropTypes from "prop-types";
import {Col, Pagination, Row, Select, Space, Table} from "antd";

import {SORT_ORDER, SORT_ORDER_VALUE} from "./../../constants/constant";

import "./grid-view.less";
const GridView = (props) => {
    // const {Option} = Select;
    const handleOnChange = (page, pageSize) => {
        const newOffset = pageSize * (page - 1);
        return props?.onPaginate(newOffset, pageSize);
    };
    const handleOnChangeTable = (pagination, filters, sort) => {
        const { onSort, clearSort } = props;
        const { columnKey, order } = sort;
        if (order === SORT_ORDER.ASC) {
            onSort(columnKey, SORT_ORDER_VALUE.ascend);
        } else if (order === SORT_ORDER.DESC) {
            onSort(columnKey, SORT_ORDER_VALUE.descend);
        } else {
            clearSort();
        }
    };

    // const handlePageSizeChange = (value) => {
    //     return props?.onPaginate(0, value);
    // };

    function itemRender(current, type, originalElement) {
        if (type === "prev") {
            return <a>Previous</a>;
        }
        if (type === "next") {
            return <a>Next</a>;
        }
        return originalElement;
    }
    // const countOpt = [10, 20, 30];

    return (
        <div className={props?.tableKey}>
            {/* {!props?.tabKey ? (
                <Row className="space-align-block gx-m-0" type="flex" justify="space-between" align="middle">
                    <Col>{props.reactFiltersRender && props.reactFiltersRender()}</Col>

                    <Col className="gx-mb-4">
                        <Space direction="vertical" align="center">
                            <Col>
                                <span className="cb-select">
                                    <Space className="noirProRegular">
                                        {"Show"}
                                        <Select
                                            suffixIcon={
                                                <span className="usd-arrow-down font-weight-300 font-size-10 cb-pl-3 cargobarn-off-white " />
                                            }
                                            style={{ border: "#d9d9d9 1px solid", borderRadius: "4px" }}
                                            size="small"
                                            value={props.pageSize}
                                            onChange={handlePageSizeChange}
                                            bordered={false}
                                            dropdownClassName="noirProRegular gx-fs-md"
                                        >
                                            {countOpt.map((item) => {
                                                return (
                                                    <Option value={item} key={item}>
                                                        <p className="font-weight-300 font-size-13">{item}</p>
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        {"items"}
                                    </Space>
                                </span>
                            </Col>
                        </Space>
                    </Col>
                </Row>
            ) : null} */}
            <Table
                loading={props?.isFetching}
                className="gx-table-header-color"
                rowKey={props?.rowKey}
                style={{
                    float: "center"
                }}
                columns={props.columns}
                tableLayout={props.tableLayout ? props.tableLayout : "fixed"}
                dataSource={props?.data}
                pagination={false}
                onChange={handleOnChangeTable}
            />
            {!props?.tabKey && !props?.isLoading ? (
                <Row justify="end" align="center" className="gx-m-0">
                    <Col className="gx-p-0">
                        <Pagination
                            className="table-pagination"
                            defaultCurrent={1}
                            hideOnSinglePage={true}
                            pageSize={props.pageSize}
                            total={props?.totalCount}
                            showSizeChanger={props.totalCount > 1000}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
};

GridView.prototype = {
    columns: PropTypes.array,
    data: PropTypes.any,
    sort: PropTypes.any,
    rowKey: PropTypes.any
};

export default GridView;

