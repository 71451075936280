export const STORAGE_KEY = {
    ADMIN_INFO: "ADMIN_INFO",
    TOKEN: "TOKEN",
    USERS_ONBOARDED: "USERS_ONBOARDED",
    DOCUMENTS_GRAPH: "DOCUMENTS_GRAPH",
    RIDES_GRAPH: "RIDES_GRAPH",
    GET_MANAGE_RIDES: "GET_MANAGE_RIDES",
    SHARERS_EARNINGS: "SHARERS_EARNINGS",
    USERS: "USERS",
    USER_DETAILS: "USER_DETAILS",
    USER_DOCUMENTS: "USER_DOCUMENTS",
    COUPEN_LISTING: "COUPEN_LISTING",
    SERVICE_RATES_LISTING: "SERVICE_RATES_LISTING",
    SERVICE_RATES_COUNTRIES: "SERVICE_RATES_COUNTRIES",
    RIDE_SUMMARY: "RIDE_SUMMARY",
    PAYMENT: "PAYMENT",
    CONFLICT_LISTING: "CONFLICT_LISTING",
    ROLES: "ROLES"
};
