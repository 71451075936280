import { Col, Menu, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LogoutModal } from "../../components";
import { MAIN_SIDE_BAR_OPTIONS } from "../../constants/Roles";
import { STORAGE_KEY } from "../../constants/Storage";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { filterForRoles } from "../../util/ResuableFunctions";
import LocalStorageService from "./../../util/local-storage.service";
import "./index.less";
import SidebarLogo from "./SidebarLogo";

const roles = LocalStorageService.get(STORAGE_KEY.ROLES);
const filteredSideBarOptions = filterForRoles(MAIN_SIDE_BAR_OPTIONS, JSON.parse(roles ?? "[]"), "linkTo");
console.log(filteredSideBarOptions);

const SidebarContent = () => {
    console.log(roles);
    let { themeType, pathname } = useSelector(({ settings }) => settings);

    const { Item } = Menu;
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const renderSideBarOptions = (props) => {
        if (!props.sideBarOptions) {
            return;
        }
        return (
            <>
                {filteredSideBarOptions.map((sidebarOption) => {
                    return (
                        <Item key={sidebarOption.selectedOptionKey}>
                            <Link to={sidebarOption.linkTo} className="poppins-regular">
                                <Row align="middle" className="gx-m-0">
                                    <Col className="gx-p-0">
                                        {selectedKeys == sidebarOption.selectedOptionKey
                                            ? sidebarOption.selectedIcon
                                            : sidebarOption.icon}
                                    </Col>
                                    <Col className="gx-p-0 sidebar-text">
                                        <span>{sidebarOption.text}</span>
                                    </Col>
                                </Row>
                            </Link>
                        </Item>
                    );
                })}
            </>
        );
    };

    let selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[0];

    return (
        <>
            {showLogoutModal && (
                <LogoutModal
                    visible={showLogoutModal}
                    onCancel={() => {
                        setShowLogoutModal(false);
                    }}
                    onSuccess={() => {
                        setShowLogoutModal(false);
                    }}
                />
            )}

            <SidebarLogo />
            <div className="gx-sidebar-content sidebar-scroll custom-sidebar-scroll">
                <Menu
                    defaultOpenKeys={[defaultOpenKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={themeType === THEME_TYPE_LITE ? "light" : "dark"}
                    mode="inline"
                    className="custom-sidebar-scroll side-menu"
                >
                    {renderSideBarOptions({ sideBarOptions: MAIN_SIDE_BAR_OPTIONS })}
                </Menu>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;
